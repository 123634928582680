import { Fragment } from 'react';
import { Box, Button, Divider, Flex, Grid, HStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { motion } from 'framer-motion';

import {
  BarGraphIcon,
  CalendarIcon,
  ChevronRightIcon,
  GoalIcon,
  Icon,
  PillarIcon,
  PresentIcon,
  TEXT,
} from '@arena-labs/strive2-ui';
import { $API } from '@strive/api';

import { CompetitionStar } from '../competition-star';

export function CompetitionBackground() {
  return (
    <Flex direction="column" h="full" justifyContent="space-evenly" pb={4}>
      <Flex direction={'column'} justifyContent="space-evenly" mt="2">
        <Box
          position={'relative'}
          px={5}
          pointerEvents={'none'}
          rounded={'sm'}
          py={2}
          border="0.5px solid"
          borderColor={'neutral.900'}
        >
          <Button px="0" variant="unstyled" opacity={0.25}>
            <HStack borderRadius="card" spacing="2" color={'logo'}>
              <Icon as={PillarIcon} boxSize={7} color="logo" />
              <TEXT.H3 textTransform={'uppercase'}>First Steps</TEXT.H3>
              <motion.div
                animate={{
                  transform: `rotate(90deg)`,
                }}
              >
                <Icon as={ChevronRightIcon} boxSize={3} />
              </motion.div>
            </HStack>
          </Button>
          <Box
            position="absolute"
            right={1}
            top={'-5px'}
            opacity={1}
            px={3}
            py="2"
            border={'1px solid'}
            rounded={'md'}
            borderColor={'tertiary.700'}
          >
            <CompetitionStar positionValue={'?'} size="gauge" />
          </Box>
        </Box>
        <Box mx="auto" pt={4}>
          <CompetitionStar positionValue={'?'} size={'info'} />
        </Box>
      </Flex>
      <Box>
        <Divider px={6} ml={-6} />
      </Box>

      <GameAndRulesExpectations />
    </Flex>
  );
}

export function GameAndRulesExpectations() {
  const { data: incentive } = $API.useGetIncentives();

  let compStartDate = null;
  let compEndDate = null;

  if (incentive?.[0]?.type === 'competition') {
    compStartDate = incentive[0].start_date;
    compEndDate = incentive[0].end_date;
  }

  const informationObjects = [
    {
      smallText: 'The path to victory',
      largeText:
        'Be consistent in your practicing your tools and keeping your HRV above your baseline!',
      Icon: GoalIcon,
    },
    {
      smallText: 'Timeline',
      largeText:
        compStartDate && compEndDate
          ? `${format(compStartDate, 'MMM d')} - ${format(
              compEndDate,
              'MMM d',
            )}`
          : 'Coach Will Communicate Competition Length!',
      Icon: CalendarIcon,
    },
    {
      smallText: 'How to win',
      largeText: 'Earn a Top 5 Ranking on the Strive Leaderboard.',
      Icon: BarGraphIcon,
    },
    {
      smallText: 'Prize',
      largeText: 'Compete for a Strive Performance Bundle.',
      Icon: PresentIcon,
    },
  ];

  return (
    <Flex direction="column" rowGap={4} justifyContent="space-evenly" mt="2">
      <TEXT.H3>What you can expect on the Leaderboard</TEXT.H3>
      <Grid
        templateColumns={'auto 1fr'}
        columnGap={3}
        rowGap={4}
        alignItems={'center'}
      >
        {informationObjects.map((infoObject) => (
          <Fragment key={infoObject.largeText}>
            <Icon as={infoObject.Icon} boxSize={'6'} color={'secondary.400'} />
            <Flex direction={'column'} gap={2}>
              <TEXT.P3 color="neutral.400">{infoObject.smallText}</TEXT.P3>
              <TEXT.P2 lineHeight={'tall'}>{infoObject.largeText}</TEXT.P2>
            </Flex>
          </Fragment>
        ))}
      </Grid>
    </Flex>
  );
}
