import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
} from '@chakra-ui/react';

import { DataTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { PillarPractice } from '@arena-labs/shared-models';
import { useModalDisclosure } from '@arena-labs/strive2-ui';

import { ACI } from './aci-2/ACI';
import { ACIPostResponse } from './aci-form.machine';
import { useACIGate } from './aci-gate';

ACIModal.useDisclosure = function () {
  return useModalDisclosure('ACI');
};

export type ACIModalProps = {
  onSuccess?: (results: ACIPostResponse) => void;
  logPracticeCard: (practice: PillarPractice) => React.ReactNode;
  banner: (onNavigateBack?: () => void) => React.ReactNode;
} & Omit<DrawerProps, 'children'>;

export function ACIModal({
  onSuccess,
  logPracticeCard,
  banner,
  ...props
}: ACIModalProps) {
  const aciGate = useACIGate();
  const handleClose = () => {
    analytics.logEvent(DataTrackingEvent.ACIModalClosed);
    props.onClose();
    aciGate.actions.reset();
  };

  const analytics = useAnalytics();

  return (
    <Drawer placement="bottom" size="full" {...props} onClose={handleClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody
          m={0}
          px="0"
          pb="calc(env(safe-area-inset-top) + 1em)"
          pt="env(safe-area-inset-top)"
          h="100dvh"
          overflowX="hidden"
          display="grid"
          gridTemplateRows="auto 1fr"
          gridTemplateAreas={`
            "banner"
            "content"
          `}
        >
          <ACI banner={banner} logPracticeCard={logPracticeCard} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
