import { MutationOptions, useQueryClient } from '@tanstack/react-query';
import { ZodiosResponseByAlias } from '@zodios/core';
import produce from 'immer';

import { HomeScreenResponse } from '@arena-labs/strive2-coaching';
import { $API, striveAppApi } from '@strive/api';

const homepageKey = $API.getKeyByAlias('getHomepage', {});
const pillarsKey = $API.getKeyByAlias('getPillars', {});

// Zodios doesn't currently support inferring the type of
// the QueryMutationContext from the `onMutate` callback.
// So we'll define this and then use type assertions later
type LogPracticeMutationContext =
  | {
      didUpdateHomepage: boolean;
    }
  | undefined;

export function useLogPractice(options: Pick<MutationOptions, 'retry'> = {}) {
  const queryClient = useQueryClient();

  return $API.useLogPractice(
    {},
    {
      ...options,
      onMutate({ practice }) {
        // Optimistically update the homepage
        const homepage =
          queryClient.getQueryData<HomeScreenResponse>(homepageKey);

        let didUpdateHomepage = false;
        if (homepage) {
          queryClient.setQueryData(
            homepageKey,
            produce(homepage, (draft) => {
              const matches =
                draft?.pillar_data?.current_pillar.practices
                  .filter((pr) => pr.slug === practice)
                  .concat(
                    draft?.learning_session?.practices?.filter(
                      (pr) => pr.slug === practice,
                    ) ?? [],
                  ) ?? [];
              matches.forEach((match) => {
                match.log_count += 1;
                didUpdateHomepage = true;
              });
            }),
          );
        }
        const pillars =
          queryClient.getQueryData<
            ZodiosResponseByAlias<typeof striveAppApi, 'getPillars'>
          >(pillarsKey);
        if (pillars) {
          queryClient.setQueryData(
            pillarsKey,
            produce(pillars, (draft) => {
              const practices = draft.flatMap((pillar) => pillar.practices);
              const match = practices.find((item) => item.slug === practice);
              if (match) {
                match.log_count += 1;
                match.completed_today = true;
              }
            }),
          );
        }

        return { didUpdateHomepage } as LogPracticeMutationContext;
      },
      onSuccess(data, variables, context) {
        // We only need to refresh the scorecard if this is a learning journey practice
        if ((context as LogPracticeMutationContext)?.didUpdateHomepage) {
          queryClient.invalidateQueries($API.getKeyByAlias('getScorecard'));
        }

        queryClient.invalidateQueries($API.getKeyByAlias('getIncentives'));
      },
      onSettled() {
        queryClient.invalidateQueries(homepageKey);
        queryClient.invalidateQueries(pillarsKey);
      },
    },
  );
}

export function useSkipPractice(options: Pick<MutationOptions, 'retry'> = {}) {
  const queryClient = useQueryClient();

  return $API.useSkipPractice(
    {},
    {
      ...options,
      onSettled() {
        queryClient.invalidateQueries($API.getKeyByAlias('getScorecard'));
        queryClient.invalidateQueries(pillarsKey);
        queryClient.invalidateQueries(homepageKey);
      },
    },
  );
}
