import { useState } from 'react';
import { Button, Fade, Flex, Icon, useMediaQuery } from '@chakra-ui/react';

import { ChevronLeftIcon, CloseIcon, TEXT } from '@arena-labs/strive2-ui';

import { AppBanner } from '../../../layout/app-banner';
import { CompetitionBackground } from './competition-background';
import CompetitionIncentive from './competition-incentive';
import CompetitionIncentiveBundle from './competition-incentive-bundle';
import CompetitionLeaderboard from './competition-leaderboard';
import CompetitionScore from './competition-score';
import CompetitionWelcome from './competition-welcome';

export type CompetitionInformationProps = {
  onClose?: () => void;
  onNavigateBack?: () => void;
  onProceed: () => void;
  incentiveOnly?: boolean;
  slug: string;
};

export default function CompetitionInformation({
  onClose,
  onProceed,
  onNavigateBack,
  incentiveOnly,
  slug,
}: CompetitionInformationProps) {
  const [currentView, setCurrentView] = useState(0);
  const [isCompact] = useMediaQuery('(max-height: 750px)');

  let excellenceViews = [
    {
      title: undefined,
      component: <CompetitionWelcome />,
      buttonText: 'Introduce Me!',
    },
    {
      title: 'Game and Rules',
      component: <CompetitionBackground />,
      buttonText: 'Calculate Score!',
    },
    {
      title: 'Calculate My Score',
      component: <CompetitionScore explanationFocus={'Practice_Consistency'} />,
      buttonText: 'Continue',
    },
    {
      title: 'Calculate My Score',
      component: <CompetitionScore explanationFocus={'HRV_Avg'} />,
      buttonText: 'Track My Progress!',
    },
    {
      title: 'Calculate My Score',
      component: <CompetitionLeaderboard />,
      buttonText: 'Strive Performance Bundle',
    },

    {
      title: slug.includes('competition_gifts')
        ? 'Strive Performance Bundle'
        : 'The Exclusive Experience',
      component: slug.includes('competition_gifts') ? (
        <CompetitionIncentiveBundle />
      ) : (
        <CompetitionIncentive />
      ),
      buttonText: slug.includes('competition_gifts')
        ? 'Stay Consistent & Compete!'
        : onNavigateBack
        ? 'Launch Your Journey'
        : 'Back To Leaderboard',
    },
  ];

  if (incentiveOnly) {
    excellenceViews = excellenceViews.filter(
      (element) =>
        element.title === 'The Exclusive Experience' ||
        element.title === 'Strive Performance Bundle',
    );
  }

  const handleBack = () =>
    currentView > 0
      ? setCurrentView(currentView - 1)
      : onNavigateBack
      ? onNavigateBack()
      : null;

  return (
    <Flex h="100%" pb="6" direction={'column'}>
      {Boolean(
        onClose || onNavigateBack || excellenceViews?.[currentView]?.title,
      ) && (
        <AppBanner color={'logo'}>
          {onNavigateBack || currentView > 0 ? (
            <Button
              onClick={() => handleBack()}
              variant={'unstyled'}
              size={'xs'}
            >
              <Icon as={ChevronLeftIcon} boxSize={4} />
            </Button>
          ) : null}

          {excellenceViews?.[currentView]?.title && (
            <TEXT.H3 gridColumn="2" textAlign={'center'} w="full">
              {excellenceViews?.[currentView]?.title}
            </TEXT.H3>
          )}

          {onClose && (
            <Flex
              gridColumn="right"
              alignItems={'center'}
              justifyContent={'flex-end'}
              w="full"
              as="button"
              onClick={() => onClose()}
            >
              <Icon as={CloseIcon} boxSize={'7'} />
            </Flex>
          )}
        </AppBanner>
      )}

      <Flex h="100%" px="6" direction={'column'}>
        <Fade
          in
          key={currentView}
          transition={{ enter: { duration: 1 } }}
          style={{ height: '100%' }}
        >
          {excellenceViews[currentView]?.component}
        </Fade>

        <Button
          mb={isCompact ? '0' : '6'}
          variant={'primary'}
          mt="auto"
          w="full"
          onClick={() => {
            if (currentView === excellenceViews.length - 1) {
              onProceed();
            } else {
              setCurrentView(currentView + 1);
            }
          }}
        >
          {excellenceViews[currentView]?.buttonText}
        </Button>
      </Flex>
    </Flex>
  );
}
