import {
  Box,
  Center,
  Collapse,
  Flex,
  Grid,
  Icon,
  VStack,
} from '@chakra-ui/react';

import { PlayIcon, TEXT } from '@arena-labs/strive2-ui';

import { UserHighRecharge } from './icons/user-high-recharge';
import { UserLowRecharge } from './icons/user-low-recharge';
import { UserNullRecharge } from './icons/user-null-recharge';

export function RechargeEstimateCard({
  selection,
}: {
  selection: string | undefined | null;
}) {
  const selectionIsAbove = selection?.includes('above');
  return (
    <Grid templateColumns={'auto 1fr'} gap={6} alignItems={'center'}>
      <Box
        p={2}
        rounded={'card'}
        bgGradient={
          selectionIsAbove
            ? 'linear-gradient(180deg, rgba(44, 44, 46, 0.232) -0.12%, rgba(149, 253, 253, 0.208) 99.99%)'
            : `linear-gradient(180deg, rgba(44, 44, 46, 0.232) 20.18%, rgba(255, 92, 0, 0.284) 99.91%)`
        }
      >
        <Icon
          as={selectionIsAbove ? UserHighRecharge : UserLowRecharge}
          boxSize={'64px'}
        />
      </Box>
      <VStack spacing={2} align={'flex-start'}>
        <TEXT.P1_SEMIBOLD mr={'auto'}>Inner State</TEXT.P1_SEMIBOLD>
        <TEXT.P1_SEMIBOLD
          mr={'auto'}
          color={selectionIsAbove ? 'ACI.aboveBaseline' : 'ACI.belowBaseline'}
        >
          Your reflection is {selectionIsAbove ? 'above' : 'below'} baseline.
        </TEXT.P1_SEMIBOLD>
      </VStack>
    </Grid>
  );
}

export function RechargeStatusCard({
  selection,
}: {
  selection: string | undefined | null;
}) {
  const selectionIsAbove = selection?.includes('above');
  return (
    <Grid templateColumns={'auto 1fr'} gap={6} alignItems={'center'}>
      <Box
        py={10}
        px={2}
        rounded={'md'}
        bgGradient={
          !selection
            ? 'linear-gradient(179.99deg, rgba(44, 44, 46, 0.232) -0.12%, rgba(205, 205, 205, 0.208) 99.99%)'
            : selectionIsAbove
            ? 'linear-gradient(180deg, rgba(44, 44, 46, 0.232) -0.12%, rgba(149, 253, 253, 0.208) 99.99%)'
            : `linear-gradient(180deg, rgba(44, 44, 46, 0.232) 20.18%, rgba(255, 92, 0, 0.284) 99.91%)`
        }
      >
        <Icon
          as={
            !selection
              ? UserNullRecharge
              : selectionIsAbove
              ? UserHighRecharge
              : UserLowRecharge
          }
          boxSize={'112px'}
        />
      </Box>
      <VStack spacing={2} align={'flex-start'}>
        <TEXT.H3 mr={'auto'}>HRV Suggests</TEXT.H3>
        <TEXT.H2
          mr={'auto'}
          color={
            !selection
              ? 'neutral.white'
              : selectionIsAbove
              ? 'ACI.aboveBaseline'
              : 'ACI.belowBaseline'
          }
        >
          {!selection
            ? `Your data is not available.`
            : `Your data is ${
                selectionIsAbove ? 'above' : 'below'
              } 8 day average.`}
        </TEXT.H2>
      </VStack>
    </Grid>
  );
}

export function InsightsRechargeCard({
  isDataCard,
  selection,
  expanded,
}: {
  selection: string | undefined | null;
  isDataCard: boolean;
  expanded: boolean;
}) {
  const selectionIsAbove = selection?.includes('above');
  return (
    <Box
      position={'relative'}
      rounded={'md'}
      w={'full'}
      bgGradient={
        !selection
          ? `
        linear-gradient(179.99deg, rgba(44, 44, 46, 0.232) -0.12%, rgba(205, 205, 205, 0.208) 99.99%);
        `
          : selectionIsAbove
          ? 'linear-gradient(180deg, rgba(44, 44, 46, 0.232) -0.12%, rgba(149, 253, 253, 0.208) 99.99%)'
          : `linear-gradient(180deg, rgba(44, 44, 46, 0.232) 20.18%, rgba(255, 92, 0, 0.284) 99.91%)`
      }
      px={4}
      pt={2}
    >
      <Center w={'full'}>
        <Icon
          as={
            !selection
              ? UserNullRecharge
              : selection?.includes('above')
              ? UserHighRecharge
              : UserLowRecharge
          }
          boxSize={'64px'}
          mx={'auto'}
          mb={2}
          alignSelf={'center'}
        />
      </Center>
      <Collapse in={expanded}>
        <TEXT.P2 textAlign={'left'} lineHeight={'taller'}>
          Your {isDataCard ? 'data' : 'reflection'} is{' '}
          {!selection ? 'not available' : selectionIsAbove ? 'above' : 'below'}{' '}
          {!selection ? null : isDataCard ? '14 day avg' : 'baseline'}.
        </TEXT.P2>
      </Collapse>

      <TEXT.P3
        as={Flex}
        flexDir={'column'}
        position={'absolute'}
        left={4}
        top={2}
      >
        {isDataCard ? (
          'HRV'
        ) : (
          <>
            <span>Inner</span>
            <span>State</span>
          </>
        )}
      </TEXT.P3>
    </Box>
  );
}

export function AdditionalInsightCards({
  metric,
  value,
  context,
  trend,
  metricIndicator,
  contextIndicator,
}: {
  trend: string | null;
  metric: string;
  value: string;
  context: string;
  metricIndicator?: boolean;
  contextIndicator?: boolean;
}) {
  const trendIncreasing = trend?.includes('above');
  return (
    <Flex
      rounded={'md'}
      direction={'column'}
      layerStyle={'1dp'}
      w={'full'}
      py={4}
      px={3}
      gap={2}
      alignItems={'flex-start'}
    >
      <TEXT.P1_BOLD>{metric}</TEXT.P1_BOLD>
      <Grid templateColumns={'auto auto'} alignItems={'center'}>
        <TEXT.H3
          color={
            !trend
              ? 'neutral.300'
              : trendIncreasing
              ? 'success.300'
              : 'error.300'
          }
        >
          {value}
        </TEXT.H3>
        {metricIndicator && (
          <Icon
            as={PlayIcon}
            color={trendIncreasing ? 'success.300' : 'error.300'}
            transform={`rotate(${trendIncreasing ? '-90' : '90'}deg)`}
            w={3}
            h={2}
            mx={1}
          />
        )}
      </Grid>

      <Grid templateColumns={'auto 1fr'} alignItems={'center'}>
        {contextIndicator && (
          <Icon
            as={PlayIcon}
            color={trendIncreasing ? 'success.300' : 'error.300'}
            transform={`rotate(${trendIncreasing ? '-90' : '90'}deg)`}
            w={3}
            h={2}
            mx={1}
          />
        )}
        <TEXT.P1_SEMIBOLD
          flexWrap={'nowrap'}
          fontSize={'12px'}
          color={'neutral.400'}
        >
          {context}
        </TEXT.P1_SEMIBOLD>
      </Grid>
    </Flex>
  );
}
