import { createActorContext } from '@xstate5/react';

import { useWearableMode } from '../lib/use-wearable-mode';
import { strivewareMachine } from './striveware-connection.machine';

export const StrivewareContext = createActorContext(strivewareMachine);

export function StrivewareProvider(props: { children: React.ReactNode }) {
  const { data } = useWearableMode();
  if (data?.mode !== 'striveware') return <>{props.children}</>;

  return (
    <StrivewareContext.Provider
      options={{ input: { wearable: data.wearable } }}
    >
      <DebugStriveware />
      {props.children}
    </StrivewareContext.Provider>
  );
}

function DebugStriveware() {
  return null;
}

export const StrivewareSelector = {
  bluetoothState: (state) =>
    state.matches({ Requirement: { Permission: 'Prompt' } })
      ? 'permission.prompt'
      : state.matches({ Requirement: { Permission: 'Denied' } })
      ? 'permission.denied'
      : state.matches('Bluetooth Ready')
      ? 'bluetooth.ready'
      : 'bluetooth.off',
  userDevice: (state) => state.context.pairedDevice,
  isConnected: (state) => state.hasTag('connected'),
  deviceState: (state) =>
    state.hasTag('syncing')
      ? 'syncing'
      : state.matches({
          'Bluetooth Ready': { Paired: { Connected: { Sync: 'Complete' } } },
        })
      ? 'synced'
      : state.matches({
          'Bluetooth Ready': { Paired: { Connected: { Sync: 'Error' } } },
        })
      ? 'sync-failed'
      : state.hasTag('connected')
      ? 'connected'
      : state.hasTag('connecting')
      ? 'connecting'
      : state.hasTag('connectFailed')
      ? 'connect-failed'
      : 'disconnected',
  deviceStatusText: (state) =>
    state.hasTag('syncing')
      ? 'Syncing'
      : state.matches({
          'Bluetooth Ready': { Paired: { Connected: { Sync: 'Complete' } } },
        })
      ? 'Connected'
      : state.matches({
          'Bluetooth Ready': { Paired: { Connected: { Sync: 'Error' } } },
        })
      ? 'Sync failed'
      : state.hasTag('connected')
      ? 'Connected'
      : state.hasTag('connecting')
      ? 'Connecting'
      : 'Not Connected',
} satisfies Record<string, Parameters<typeof StrivewareContext.useSelector>[0]>;
