import { useAsyncFn } from 'react-use';
import { Button, Divider, Flex, Grid, Text } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import {
  ChevronLeftIcon,
  CycleIcon,
  Icon,
  PenIcon,
  SVGIconComponentType,
  TargetIcon,
  TEXT,
  TrendChartIcon,
  useResponsive,
} from '@arena-labs/strive2-ui';
import { AppGateProps } from '@strive/api';

import { AppBanner } from '../../layout/app-banner';

export function CompetitionCompletionStats({
  onClose,
  stats,
}: {
  onClose: () => unknown;
  stats: AppGateProps<'competition_completed'>;
}) {
  const swiper = useSwiper();
  const rs = useResponsive();

  // Show a loading state after the user clicks the "Keep Practicing" button
  const [loadingState, startLoading] = useAsyncFn(
    (ms: number) => new Promise((resolve) => setTimeout(resolve, ms)),
  );
  const handleClose = () => {
    if (loadingState.loading) return;
    onClose();
    startLoading(3000); // submit appgate & refresh homepage
  };

  return (
    <Flex h="full" direction={'column'}>
      <AppBanner>
        <Button
          onClick={() => swiper.slidePrev()}
          variant={'unstyled'}
          size={'xs'}
        >
          <Icon as={ChevronLeftIcon} boxSize={4} />
        </Button>
        <TEXT.H3 textAlign={'center'} w="full">
          My Achievement
        </TEXT.H3>
      </AppBanner>
      <Flex
        direction="column"
        gap="4"
        px="6"
        py="4"
        color="neutral.400"
        bg="bg.primary"
      >
        <Flex direction="row" gap="4" sx={{ '> *': { flexBasis: '50%' } }}>
          <CompetitionStat
            icon={TargetIcon}
            label="Final Score"
            value={stats.score}
          />
          <CompetitionStat
            icon={PenIcon}
            label="Tools Practiced"
            value={stats.total_practices}
          />
        </Flex>
        <CompetitionStat
          icon={CycleIcon}
          label="Consistently Practiced"
          value={`${stats.consistency}%`}
          extra="of days on Strive"
        />
        <CompetitionStat
          icon={TrendChartIcon}
          label="Maintained HRV above average for"
          value={`${stats.hrv_above_avg}%`}
          extra="of days on Strive"
        />
      </Flex>
      <Divider my="auto" />
      <Flex direction="column" gap="4" textAlign="center" align="center" px="6">
        <Text textStyle="h2" color="white">
          Stay on the Path.
        </Text>
        <Text textStyle={rs({ base: 'p1', xs: 'p2' })}>
          While this competition is over, your journey is just beginning. Your
          dashboard will remain available to track your progress and rank
          amongst your peers.
        </Text>
        <Text
          textStyle={rs({ base: 'p1', xs: 'p2' })}
          mt={rs({ base: 4, xs: 2 })}
        >
          Maintain your pursuit of excellence and nurture progress through
          steady practice.
        </Text>
      </Flex>
      <Flex direction="column" mt="auto" gap="3" py="4" px="6">
        <Button
          fontSize="p2"
          variant="unstyled"
          textDecoration="underline"
          height="auto"
          alignSelf="center"
          onClick={() => swiper.slidePrev()}
        >
          Back to previous page
        </Button>
        <Button
          variant="primary"
          width="full"
          mt="auto"
          isLoading={loadingState.loading}
          onClick={() => {
            if (swiper.isEnd) {
              handleClose();
            } else {
              swiper.slideNext();
            }
          }}
        >
          {swiper.isEnd ? 'Keep Practicing' : "What's Next?"}
        </Button>
      </Flex>
    </Flex>
  );
}

function CompetitionStat({
  icon,
  label,
  value,
  extra,
}: {
  icon: SVGIconComponentType;
  label: string;
  value: string | number;
  extra?: string;
}) {
  return (
    <Grid
      templateColumns="1rem 1fr"
      columnGap="4"
      px="4"
      py="2"
      borderRadius="lg"
      border="1px solid"
      borderColor="neutral.900"
    >
      <Icon as={icon} color="icon.primary" placeSelf="center" />
      <Text textStyle="p2" alignSelf="center">
        {label}
      </Text>
      <Flex gridColumn="2" gap="2" align="baseline">
        <Text textStyle="h1" color="white">
          {value}
        </Text>
        {extra ? <Text textStyle="p2">{extra}</Text> : null}
      </Flex>
    </Grid>
  );
}
