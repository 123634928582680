import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Swiper } from 'swiper';

import { PillarPractice } from '@arena-labs/shared-models';
import { useFadeEntryFadeExit } from '@arena-labs/strive2-ui';

import { AciContext } from '../aci-context';
import { useAciLessonLogic } from '../use-aci-lesson-logic';
import { DailyInsights } from './daily-insights';

export function CheckInInsights({
  onOpenInfo,
  logPracticeCard,
}: {
  onOpenInfo: () => void;
  logPracticeCard: (practice: PillarPractice) => React.ReactNode;
}) {
  const aciActor = AciContext.useActorRef();
  const showLessonPrompt = AciContext.useSelector((state) =>
    state.matches({ Open: { insights: 'LessonPrompt' } }),
  );
  const aciLessonLogic = useAciLessonLogic();

  const aciResponse = AciContext.useSelector(
    (state) => state.context.aciResponse,
  );

  const { scope } = useFadeEntryFadeExit();
  const [swiper, setSwiper] = useState<Swiper>();

  useEffect(() => {
    if (!swiper) {
      const newSwiper = new Swiper('.swiper', {
        speed: 500,
        spaceBetween: 0,
        initialSlide: 1,
        slidesPerView: 1,
        allowTouchMove: false,
      });
      setSwiper(newSwiper);
    }
  }, [swiper]);

  return (
    <Box ref={scope} h={'full'}>
      <Flex direction={'column'} id={'body'} h={'full'}>
        <Box h={'full'} maxW={'100vw'} paddingY={6} mx={-6}>
          <Box h={'full'}>
            {aciResponse && (
              <DailyInsights
                data={aciResponse}
                logPracticeCard={logPracticeCard}
                onOpenContext={onOpenInfo}
              />
            )}
          </Box>
        </Box>

        <Button
          mt={'auto'}
          w={'full'}
          variant={'primary'}
          transition={'all 1s ease'}
          onClick={() => {
            aciActor.send({
              type: 'close',
              hasPendingLesson: aciLessonLogic.hasIncompleteLesson(),
            });
          }}
        >
          Done
        </Button>
      </Flex>
      <Modal
        isOpen={showLessonPrompt}
        variant="bottom-sheet"
        scrollBehavior="inside"
        onClose={aciLessonLogic.close}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textStyle="h2" textAlign="center" py="6">
            Move on to lesson?
          </ModalHeader>

          <ModalFooter
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            gap="4"
          >
            <Button
              variant="secondary"
              boxShadow="8dp"
              w="full"
              onClick={aciLessonLogic.close}
            >
              Exit
            </Button>
            <Button
              variant="primary"
              boxShadow="8dp"
              w="full"
              onClick={aciLessonLogic.openLesson}
            >
              Go to Lesson
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
