var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"strive@3.22.0-8a687903"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { escapeRegExp } from 'lodash-es';

import { apiHost, getResourcesS3Url } from '@strive/api';

const SENTRY_DSN =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || '';

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT || 'development',
    release: process.env.SENTRY_RELEASE || undefined,
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    // @see https://docs.sentry.io/platforms/javascript/session-replay/configuration/
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      // @see https://docs.sentry.io/platforms/javascript/session-replay/privacy/
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [apiHost, getResourcesS3Url()],
        networkRequestHeaders: ['Timezone', 'X-Trace-Id'],
      }),

      // @see https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation
      Sentry.browserTracingIntegration({
        tracePropagationTargets: [
          'localhost',
          new RegExp(`^${escapeRegExp(apiHost)}`),
        ],
      }),
    ],
  });
}
