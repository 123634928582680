import { Flex } from '@chakra-ui/react';

import { ToolKitTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { PillarPractice } from '@arena-labs/shared-models';
import { BrainIcon, Card, Icon, InfoIcon } from '@arena-labs/strive2-ui';
import { useMediaPlayer } from '@strive/av';

export type PracticeCardButtonsProps = {
  practice: PillarPractice;
  pillar: number;
};

export function PracticeCardButtons({
  practice,
  pillar,
}: PracticeCardButtonsProps) {
  const analytics = useAnalytics({
    pillar,
    practiceSlug: practice.slug,
    practiceName: practice.title,
  });

  const { openPlayer } = useMediaPlayer({ analytics });

  return (
    <Flex justifyContent="space-between">
      <Card.Button
        variant="outline"
        onClick={() => {
          analytics.logEvent(ToolKitTrackingEvent.ToolIntro);
          openPlayer(practice.intro);
        }}
      >
        <Flex gap="1" alignItems="center">
          <Icon as={InfoIcon} size="4" />
          Introduction
        </Flex>
      </Card.Button>

      <Card.Button
        variant="outline"
        onClick={() => {
          analytics.logEvent(ToolKitTrackingEvent.ToolGuideMe);
          openPlayer(practice.coach_me);
        }}
      >
        <Flex gap="1" alignItems="center">
          <Icon as={BrainIcon} size="4" />
          Coach me
        </Flex>
      </Card.Button>
    </Flex>
  );
}
