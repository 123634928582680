import NextLink from 'next/link';
import { useRouter } from 'next/router';
import {
  Box,
  Center,
  Flex,
  Grid,
  HStack,
  Icon,
  Link,
  useDisclosure,
} from '@chakra-ui/react';
import { captureException } from '@sentry/nextjs';
import { z } from 'zod';

import { ToolKitTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { PillarPractice } from '@arena-labs/shared-models';
import { usePracticeCache } from '@arena-labs/strive2-content';
import {
  CheckIcon,
  ChevronRightIcon,
  Elevation,
  pluralize,
  PracticeIcon,
  ProductEducationPopover,
  TEXT,
} from '@arena-labs/strive2-ui';
import { queryClient } from '@strive/api';
import { haptics } from '@strive/device';
import { useDataStore } from '@strive/utils';

import { useDaysOnStrive } from '../profile/use-days-on-strive';
import { useLogPractice } from './use-log-practice';

export type LogPracticeCardProps = {
  practice: PillarPractice;
  isChecked?: boolean;
  highlightChecked?: boolean;
  onMutate?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  showStats?: boolean;
  disclosure?: ReturnType<typeof useDisclosure>;
  displayStyle?: 'simple';
};
export function LogPracticeCard({
  practice,
  isChecked = practice?.completed_today,
  highlightChecked,
  onMutate,
  onSuccess,
  onError,
  showStats = true,
  disclosure,
  displayStyle,
}: LogPracticeCardProps) {
  const route = useRouter();
  const defaultDisclosure = useDisclosure();
  const extraInformation = disclosure ?? defaultDisclosure;
  const setPracticeCache = usePracticeCache();
  const updatePractice = useLogPractice({ retry: 3 });
  const analytics = useAnalytics({
    practiceSlug: practice?.slug,
    practiceName: practice?.title,
  });

  const { data: daysOnStrive } = useDaysOnStrive();
  const [persistedTabIndex] = useDataStore({
    key: `All_Practices_Or_Favorites`,
    schema: z.number(),
    fallback: 0,
  });

  function handleLinkClick() {
    setPracticeCache(practice);
    analytics.logEvent(ToolKitTrackingEvent.ToolOpened);
  }

  const practiceIsChecked =
    isChecked || updatePractice.isLoading || updatePractice.isSuccess;

  const handleSubmit = async () => {
    if (updatePractice.isLoading) {
      return;
    }
    try {
      analytics.logEvent(ToolKitTrackingEvent.ToolCompleted, {
        practice: practice.slug,
      });
      onMutate?.();
      await updatePractice.mutateAsync(
        { practice: practice.slug },
        {
          onSuccess: () => {
            queryClient.invalidateQueries();
            onSuccess && onSuccess();
          },
          onError,
        },
      );
    } catch (e) {
      console.error(`Error logging practice ${practice.slug}`, e);
      captureException(e);
    }
  };

  const practiceLogButton = (
    <Box
      as="button"
      aria-label="I have completed this practice"
      aria-pressed={practiceIsChecked}
      placeSelf="center"
      h={8}
      w={8}
      bg={practiceIsChecked ? 'primary.700' : 'transparent'}
      border="4px solid"
      borderRadius="sm"
      onClick={() => {
        if (!practiceIsChecked) {
          haptics.impact('LIGHT');
          handleSubmit();
        }
      }}
      borderColor="primary.700"
    >
      {practiceIsChecked && (
        <Icon
          as={CheckIcon}
          boxSize={6}
          strokeWidth="4px"
          color="neutral.900"
        />
      )}
    </Box>
  );
  return (
    <Elevation
      level="2dp"
      w="full"
      borderRadius="card"
      p="3"
      pb={extraInformation.isOpen ? 1 : 3}
      bg={
        displayStyle === 'simple' && practice.completed_today
          ? 'primaryBeta.20'
          : practiceIsChecked && highlightChecked
          ? 'primaryAlpha.20'
          : undefined
      }
    >
      <Grid w="full" templateColumns=" 30px 1fr 40px" alignItems={'center'}>
        {displayStyle === 'simple' ? (
          <TEXT.P1_BOLD gridColumn={2}>
            {practiceIsChecked ? 'Completed Today' : 'Complete Today?'}
          </TEXT.P1_BOLD>
        ) : (
          <>
            <Center h="full">
              <PracticeIcon
                slug={practice.slug}
                boxSize={'30px'}
                pointerEvents="none"
              />
            </Center>

            <Flex direction="column" justify="center" w="full" pl="2" gap="1">
              <Link
                as={NextLink}
                href={`/practices/${practice.slug}`}
                onClick={() => handleLinkClick()}
              >
                <HStack>
                  <TEXT.P1_SEMIBOLD lineHeight="normal" color={'neutral.white'}>
                    {practice.short_title}
                  </TEXT.P1_SEMIBOLD>
                  <Icon
                    as={ChevronRightIcon}
                    boxSize={3}
                    color="neutral.white"
                  />
                </HStack>
              </Link>

              {showStats ? (
                <TEXT.P3>
                  {practice.log_count}&nbsp;
                  {`${pluralize(practice.log_count, 'Completion')}`}
                  {practice.streak > 0 ? (
                    <span>
                      &nbsp; &#x2022; &nbsp;
                      {practice.streak}&nbsp;
                      {`${pluralize(practice.streak, 'Day')}`}&nbsp;Streak
                    </span>
                  ) : null}
                </TEXT.P3>
              ) : null}
            </Flex>
          </>
        )}
        {practice.short_title === 'Destress Breath' ? (
          <ProductEducationPopover
            id={'Practice_Complete_Button'}
            placement={'bottom'}
            shouldRender={
              persistedTabIndex === 0 &&
              route.asPath.startsWith('/practices') &&
              Boolean(daysOnStrive && daysOnStrive >= 2)
            }
            autoWidth
            title={'Mark Tool as Complete'}
            renderCTAButton
            body="Logging your tools each day leads to deeper insights from your coach over time. Consistency is key!"
          >
            {practiceLogButton}
          </ProductEducationPopover>
        ) : (
          practiceLogButton
        )}
      </Grid>
    </Elevation>
  );
}
