import { JStyle } from './jstyle';
import { getDataIterator } from './lib/data-iterator';

export class JStyleData {
  /** Async iterator for getting HRV data. */
  static getHrv = getDataIterator(JStyle.getHrvData, 'hrvDataResult');

  /** Async iterator for getting Sleep data. */
  static getSleep = getDataIterator(JStyle.getSleepData, 'sleepDataResult');

  /** Async iterator for getting Single HR data. */
  static getSingleHr = getDataIterator(
    JStyle.getSingleHrData,
    'singleHrDataResult',
  );

  /** Async iterator for getting Continuous HR data. */
  static getContinuousHr = getDataIterator(
    JStyle.getContinuousHrData,
    'continuousHrDataResult',
  );

  /** Collects all data points from an asynchronous iterator. */
  static async collect<T>(asyncIterable: AsyncIterable<T>): Promise<T[]> {
    const result = [];
    for await (const item of asyncIterable) {
      result.push(item);
    }
    return result;
  }
}
