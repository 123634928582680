import { useRouter } from 'next/router';

import { isMediaPlayedThisSession } from '@arena-labs/shared-models';
import { useHomepage } from '@arena-labs/strive2-coaching';

import { AciContext } from './aci-context';

export function useAciLessonLogic() {
  const [homepage, { send }] = useHomepage();
  const aciActor = AciContext.useActorRef();
  const router = useRouter();

  return {
    hasIncompleteLesson() {
      return homepage?.learning_session?.media_items.some(
        (media) => !isMediaPlayedThisSession(media),
      );
    },
    openLesson() {
      send('Close Landing');
      if (router.pathname !== '/') {
        router.push('/');
      }
      aciActor.send({ type: 'close' });
    },
    close() {
      aciActor.send({ type: 'close' });
    },
  };
}
